import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    isDisabledButton : false,
}

export const pageSlice = createSlice({
	name: 'base/page',
	initialState,
	reducers: {
        setIsDisabledButton: (state, action) => {
            state.isDisabledButton = action.payload
        }
	},
})

export const { setIsDisabledButton } = pageSlice.actions

export default pageSlice.reducer