import apiConstents from "views/tene/utils/constent"
import ApiService from "./ApiService"

export async function apiStatesList(data) {
    return ApiService.fetchData({
        url: '/states?'+apiConstents.allDataLimit,
        method: 'get',
    })
}

export async function apiDistrictList(data) {
    return ApiService.fetchData({
        url: '/districts?[fields][0]=name&populate[state][fields][0]=name&pagination[pageSize]=150',
        method: 'get',
    })
}

export async function getApipartnerStateDistrict(data) {
    return ApiService.fetchData({
        url: `partner/10/districts`,
        method: 'get',
    })
}

// export async function apiLanguagesList(data) {
//     return ApiService.fetchData({
//         url: `languages?${apiConstents.allDataLimit}`,
//         method: 'get',
//     })
// }

export async function apiGetSubscriptionCharges(data) {
    return ApiService.fetchData({
        url: `subscription-charges?${apiConstents.allDataLimit}`,
        method: 'get',
    })
}


export async function apiGetContentManagers(data) {
    return ApiService.fetchData({
        url: `/users?${apiConstents.allDataLimit}&${apiConstents.populateUserByRole}${apiConstents.rolesMapping.authenticated}`,
        method: 'get',
    })
}


export async function apiUploads(data) {
    return ApiService.fetchData({
        url: `/upload`,
        method: 'post',
        data
    })
}


export async function apiDeleteFilesFromS3(id) {
    return ApiService.fetchData({
        url: `/upload/files/${id}`,
        method: 'delete'
    })
}