// require('dotenv').config()
const appConfig = {
    baseUrl: process.env.REACT_APP_API_BASE_URL || 'http://localhost:1337/api',
    partnerWebBaseUrl: process.env.REACT_APP_PARTNER_WEB_BASE_URL || 'http://localhost:3001/sign-in/',
    apiPrefix: '/api',
    authenticatedEntryPath: '/tene/partnermanagement',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form'
}

export default appConfig