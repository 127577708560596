import React from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1024;
const getKB = (size) =>  (((size)/BYTE)/BYTE).toFixed(4);

const FileIcon = ({children}) => {
	return <span className="text-4xl">{children}</span>
}

const FileItem = props => {

	const { file, children } = props
	const { type, name, size, mime, url} = file

	const renderThumbnail = () => {
		let fileType = type || mime;
		const isImageFile = fileType.split('/')[0] === 'image' 

		if(isImageFile) {
			return <img 
				className="upload-file-image img-fluid" 
				src={url} //{URL.createObjectURL(file)} 
				alt={`file preview ${name}`}
				style={{width:'auto',height:'100%'}}
			/>
		}

		if(fileType === 'application/zip') {
			return <FileIcon><VscFileZip /></FileIcon>
		}

		if(fileType === 'application/pdf') {
			return <FileIcon><VscFilePdf /></FileIcon>
		}

		return <FileIcon><VscFile /></FileIcon>
	}

	return (
		<div className="w-full">
			<div className="flex w-full flex-col justify-center">
				<div className="upload-file-thumbnail w-full">
					{renderThumbnail()}
				</div>
				<br/>
				<div className="upload-file-info text-center w-full whitespace-normal">
					<h6 className="upload-file-name">{name}</h6>
					{/* <span className="upload-file-size">{getKB(size)} MB</span> */}
				</div>
			</div>
			{children}
		</div>
	)
}

export default FileItem
