import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignUp } from 'services/AuthService'
import { getMyDetails } from 'services/CommonService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { ADMIN, Content_Manager, Crop_Health_Manager } from 'constants/roles.constant'
import { getAuthenticatedEntryPointForLoggedInuser } from './redirectHook'
const { tene_PREFIX_PATH } = require("constants/route.constant");

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { token, signedIn } = useSelector((state) => state.auth.session)
	const signIn = async (values) => {
		try {
			const resp = await apiSignIn(values)
			navigate('/initial-loading')
			const redirectUrl = values.password
			.indexOf(process.env.REACT_APP_default_password_prefix || 'default') === 0 ? `${tene_PREFIX_PATH}/change-password`: null;
			await performSignIn(resp.data.jwt, dispatch, navigate, redirectUrl);
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.error?.message || errors.toString()
			}
		}
	}

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				const { token } = resp.data
				dispatch(onSignInSuccess(token))
				if (resp.data.user) {
					dispatch(setUser(resp.data.user || {
						avatar: '',
						userName: 'Anonymous',
						authority: ['USER'],
						email: ''
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const handleSignOut = () => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		// await apiSignOut()
		handleSignOut()
	}

	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut
	}
}

const performSignIn = async (jwt, dispatch, navigate, redirectURL) => {

	dispatch(onSignInSuccess(jwt))
	setTimeout(async () => {
		const me = await getMyDetails();
		const user = {
			id: me.data.id,
			userName: me.data.username,
			email: me.data.email,
			authority: getUserAuthorities(me.data),//['admin', 'user'],
			accountUserName: me.data.name,
			avatarId: me?.data?.avatar?.id,
			...me.data
		};
		const url = me?.data?.avatar?.formats?.thumbnail?.url;
		dispatch(setUser({ ...user, avatar: url }))
		// setTimeout(async ()=> {
			if(getAuthenticatedEntryPointForLoggedInuser(user.authority) === "/access-denied"){
				navigate(getAuthenticatedEntryPointForLoggedInuser(user.authority))
			}else{
				navigate(redirectURL || getAuthenticatedEntryPointForLoggedInuser(user.authority))
			}
		// navigate(redirectURL || getAuthenticatedEntryPointForLoggedInuser(user.authority))
		// }, 0)
	}, 1)
}

const getUserAuthorities = (user) => {
	const authorities = []
	const teneRoles = user?.role;
	// // Is Admin role?
	if (teneRoles?.id === parseInt(process.env.REACT_APP_PARTNER_ROLE_tene_authenticated_user)) {
		authorities.push(ADMIN)
	}
	// Is Crop_Health_Manager role?
	if (teneRoles?.id === parseInt(process.env.REACT_APP_PARTNER_ROLE_crop_health_manager)) {
		authorities.push(Crop_Health_Manager)
	}
	// Is Content_Manager role?
	if (teneRoles?.id === parseInt(process.env.REACT_APP_PARTNER_ROLE_content_manager)) {
		authorities.push(Content_Manager)
	}
	if(!authorities?.length){
		authorities.push("No Authority")
	}
	return authorities;
}

export default useAuth