import { trimJsonObject } from "utils/trimJSONObject";
import BaseService from "./BaseService";

const ApiService = {
  fetchData(param) {
    const modifiedData = param?.data?.data ? { ...param, data: { data: trimJsonObject(param?.data?.data) } } : param;
    return new Promise((resolve, reject) => {
      BaseService(modifiedData)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

export default ApiService;
