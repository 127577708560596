const apiConstents = {
       partnersBusinessAccountTypes: [{ value: 'savings', label: 'Savings' }, { value: 'current', label: 'Current' }, { value: 'salary', label: 'Salary' }, { value: 'fixedDeposit', label: 'Fixed Deposit' }, { value: 'recurringDeposit', label: 'Recurring Deposit' }, { value: 'nri', label: 'NRI' }],
       partnerPopulate: 'populate=partnertypes,crop_connects,languages,districts,tenePartnerUsers,tenePartnerUsers.role,tenePartnerUsers.tene_partner_user_info,tenePartnerUsers.tene_partner_user_info.tene_partner_roles,logo,contractDocs,splashScreen,partner_business_detail,partner_business_detail.farmer,partner_business_detail.farmAssist,partner_business_detail.inputLead,partner_business_detail.requestForwarding,content_manager,partner_contract_detail,crop_healths',
       paginationPage: 'pagination[page]',
       paginationPageSize: 'pagination[pageSize]',
       refPartner: 'api::partner.partner',
       filesPathPartner: 'partner',
       allDataLimit: 'pagination[limit]=-1',
       refPartnerContractDocs: 'api::partner.partner',
       populateUserByRole: 'populate=role&filters[role][id][$eq]=',
       rolesMapping: {
              authenticated: parseInt(process.env.REACT_APP_PARTNER_ROLE_content_manager)
       },
       cropPopulate: 'populate=crop_connect',
       problemPopulate:'populate=pg_timelines,pg_timelines.pg_time_line_practices',
       timelineSort : '?sort=name',
       timelineFilter : '&populate=problem_group,pg_time_line_practices&filters[problem_group][id][$eq]=',
       cropListSort : '&sort=crop_connect.cropName',
       problemListSort : '&sort=value',
       shortName:'?filters[$and][0][shortName][$eq]=',
       refUser:'plugin::users-permissions.user',
       filesUser:'user'
}
export default apiConstents;
//?pagination[limit]=-1