import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    list: [{href:'./', label:'Default'}, {href:'./', label:'Home'}, {href: null, label: "breadcrumb"}]
}

export const breadCrumbSlice = createSlice({
	name: 'base/breadCrumb',
	initialState,
	reducers: {
        setBreadCrumb: (state, action) => {
            state.list = action.payload
        }
	},
})

export const { setBreadCrumb } = breadCrumbSlice.actions

export default breadCrumbSlice.reducer