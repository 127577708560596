const { ADMIN, Crop_Health_Manager, Content_Manager } = require("constants/roles.constant");
const { tene_PREFIX_PATH } = require("constants/route.constant");

exports.getAuthenticatedEntryPointForLoggedInuser = (authorities) => {
	if(authorities.find(ele => ele === ADMIN)) { 
		return `${tene_PREFIX_PATH}/partnermanagement`; 
	}else if(authorities.find(ele => ele === Crop_Health_Manager)) { 
		return `${tene_PREFIX_PATH}/crophealthglobal`; 
	}else if(authorities.find(ele => ele === Content_Manager)) { 
		return `${tene_PREFIX_PATH}/mypartners`; 
	}else { 
		return `/access-denied`; 
	}
}