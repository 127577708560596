import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    resetPasswordEmail:''
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        updateResetPasswordEmail: (state, action) => {
            state.resetPasswordEmail = action.payload;
        },
	},
})

export const { setUser, updateResetPasswordEmail } = userSlice.actions

export default userSlice.reducer