import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/auth/local',
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}

export async function apiChangePassword (data) {
    return ApiService.fetchData({
        url: '/auth/change-password',
        method: 'post',
        data
    })
}

export async function apiAskOtpResetPassword (data) {
    return ApiService.fetchData({
        url: `auth/reset-password-request`,
        method: 'post',
        data
    })
}

export async function apiVerifyOtpResetPassword (data) {
    return ApiService.fetchData({
        url: `auth/verify/reset-password-request`,
        method: 'post',
        data
    })
}
