import { createSlice } from '@reduxjs/toolkit'
import { apiDeleteFilesFromS3 } from 'services/CommonAPI'

export const initialState = {
    currentRouteKey: '',
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        }
	},
})

export const { setCurrentRouteKey } = commonSlice.actions

export default commonSlice.reducer

export const deletePreviousFileFromS3 = async (id, SuccessCallBack, ErrorCallBack) => {
    await apiDeleteFilesFromS3(id).then(({ status}) => {
        if (status && status === 200) {
            SuccessCallBack(true)
        } else {
            ErrorCallBack(false)
        }
    }).catch((error) => { ErrorCallBack(false) })
}
