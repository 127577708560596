import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import breadCrumb from './breadCrumbSlice'
import pageAction from './pageActionSlice'
const reducer = combineReducers({
    common,
    breadCrumb,
    pageAction
})

export default reducer